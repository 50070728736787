<template>
	<div
		class="full-height pa-10-20"
	>
		<h6>
			{{ program.name }}
		</h6>

		<Search
			class="mt-10 box-ddd"
			:search="search"
			:option="search_option"

			@click="getSearch(1)"
			@reset="reset"
			@setSearchDateType="setSearchDateType"
		>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.type"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.monthly_type"
					:key="'type_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.paymentType"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.monthly_payment"
					:key="'type_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.payment"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.monthly_payment_state2"
					:key="'status_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
			<select
				slot="add"
				class="pa-5-10 mr-10"

				v-model="search.state"
				@change="getSearch(1)"
			>
				<option
					v-for="add in codes.monthly_payment_state"
					:key="'status_' + add.code"
					:value="add.code"
				>{{ add.name }}</option>
			</select>
		</Search>

		<div
			class="mt-10 box-ddd"
		>
			<div
				class="pa-10 bg-identify"
			>
				{{ search.sDate | transDate }} ~ {{ search.eDate | transDate }}
			</div>
			<div
				v-if="summary.length > 0"
				class=" bg-white justify-space-between"
			>
				<div class="pa-20 flex-1 bg-icon-calc-quest border-right">
					<div class="color-identify">{{ summary[0].name }}</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary[0].count | makeComma}}건 / {{ summary[0].amount | makeComma }}원</div>
				</div>

				<div class="pa-20 flex-1 bg-icon-calc border-right">
					<div class="color-identify">{{ summary[1].name }}</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary[1].count | makeComma}}건 / {{ summary[1].amount | makeComma }}원</div>
				</div>

				<div class="pa-20 flex-1 bg-icon-ext">
					<div class="color-identify">{{ summary[2].name }}</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary[2].count | makeComma}}건</div>
				</div>
			</div>
		</div>

		<div class="mt-10 pa-10 box-ddd bg-white height-20 flex-column overflow-y-auto">
			<div class="justify-space-between">

				<div>
					<button
						class="bg-identify pa-4-10 mr-10 vertical-middle"
						@click="isDeposit"
					>선택 납입 완료</button>
				</div>

				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12 mr-10"
						@click="toExcel2"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">대량 이체파일</span></button>

					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch(1)"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>
			</div>

			<table
				v-if="items.length > 0"
				class="mt-10 table table-even"
			>
				<colgroup>

					<col width="40px" />
					<col width="80px" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="180px" />
				</colgroup>
				<thead>
				<tr>
					<th>
						<input
							v-model="is_all"
							type="checkbox"
						>
					</th>
					<th>NO</th>
					<th>납입일</th>
					<th>서비스</th>
					<th>구분</th>

					<th>납입구분</th>
					<th>납입금액</th>
					<th>수수료</th>
					<th>납입 계좌</th>
					<th>임대인</th>

					<th>입금자</th>
					<th>결제 상태</th>
					<th>납입 상태</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>
						<input
							v-model="item.is_select"
							type="checkbox"
						>
					</td>
					<td>{{ item.idx }}</td>
					<td>{{ item.paymentDate | transDate}}</td>
					<td>{{ item.service_name }}</td>
					<td>{{ item.type_name }}</td>

					<td>{{ item.payment_name }}</td>
					<td class="text-right">{{ item.amount | makeComma }}원</td>
					<td class="text-right">{{ item.fee | makeComma }}원</td>
					<td>{{ item.account}}</td>
					<td>{{ item.lessor}}</td>

					<td>{{ item.depositor}}</td>
					<td
						class="position-relative"
						:class="'color-' + item.state_color"
					>
						<img
							v-if="!item.paymentState && item.sendKakao"
							src="@/assets/images/icon/icon-kakao.svg"
							class="img-kakao position-absolute"
							title="카카오 전송 완료"
						/>
						<v-icon
							v-else-if="!item.paymentState && !item.sendKakao"
							class="color-red icon-fail position-absolute"
							small
							title="결제실패건입니다. 카카오톡으로 다시 결제 요청을 보내주세요"
						>mdi mdi-alert-circle</v-icon>
						{{ item.state_name }}
					</td>
					<td :class="'color-' + item.payment_state_color">{{ item.payment_state_name }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center mt-10 top-line-identify"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 "
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
				@click="getSearch"
			></Pagination>

		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"

			@finish="is_excel = !is_excel"
		></Excel>
		<Excel
			v-if="is_excel2"
			:excel_data="excel_data2"

			@finish="is_excel2 = !is_excel2"
		></Excel>

		<Modal
			:is_modal="is_modal"
			:option="{}"
			@close="close"
		>
			<div
				slot="modal-content"
			>
				<MonthlyPaymentDetail
					:item="item"
					:user="user"
					:service="search.service"


					@toKakao="toKakao"
					@doConfirm="doConfirm"
					@close="close"
				></MonthlyPaymentDetail>
			</div>
		</Modal>

		<Modal
			:is_modal="is_modal2"
			:option="{}"
			:top="true"
			:bottom="true"

			title="선택 납입 완료"
			content="선택하신 항목을 납입완료처리 하시겠습니까?"
			width="380px"
			content_class="ptb-30"

			@close="close"
			@click="doConfirm"
			@cancel="close"
		>
		</Modal>

		<Modal
			:is_modal="is_modal3"
			:option="{}"
			:top="true"
			width="380px"

			title="카카오톡 링크 결제"
			@close="close"
			@cancel="toDetail(item)"
		>
			<div
				slot="modal-content"
			>
				<MonthlyPaymentkakao
					:item="item"
					:user="user"
					:service="search.service"

					@cancel="toDetail(item)"
					@setNotify="setNotify"
					@success="close"
					@onLoading="$emit('onLoading')"
					@offLoading="$emit('offLoading')"
				></MonthlyPaymentkakao>
			</div>
		</Modal>

	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Search from "@/view/Layout/Search";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";
import MonthlyPaymentDetail from "@/view/Monthly/MonthlyPaymentDetail";
import MonthlyPaymentkakao from "@/view/Monthly/MonthlyPaymentKakao";

export default {
	name: 'QuickSettlementList',
	components: {MonthlyPaymentkakao, MonthlyPaymentDetail, Modal, Excel, Search, Pagination},
	data: function(){
		return {
            user: [],
			program: {
				name: '월세 납입'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				route: this.$route.name
                ,page: this.$route.query.page ? this.$route.query.page : 1
                ,size: this.$route.query.size ? this.$route.query.size : 10
                ,search_type: this.$route.query.search_type ? this.$route.query.search_type : ''
                ,keyword: this.$route.query.keyword ? this.$route.query.keyword : ''
				,search_value: this.$route.query.search_value ? this.$route.query.search_value : ''
				,sDate: ''
				,eDate: ''
				,search_date_type: this.$route.query.search_date_type ? this.$route.query.search_date_type : 'weekly'
				,service: this.$route.query.service ? this.$route.query.service : '0'
				,type: this.$route.query.type ? this.$route.query.type : ''
				,paymentType: this.$route.query.paymentType ? this.$route.query.paymentType : ''
				,payment: this.$route.query.payment ? this.$route.query.payment : ''
				,state: this.$route.query.status ? this.$route.query.status : ''
				,startDate: ''
				,endDate: ''
				,total_count: 0
			}
			,search_option: {
				sDate: true
				,eDate: true
				,search_type: [
                    {name: '가맹점명', column: 1}
                    , {name: '대표자명', column: 2}
                    , {name: '입금자명', column: 3}
                    , {name: '아이디', column: 4}
				]
				,is_search_date_type: true
			}
			,is_excel: false
			,is_excel2: false
			,excel_data: {
				name: '월세 납입 내역'
				,header: [
					{ key: 0, name: 'NO', column: 'idx'}
					,{ key: 0, name: '납입일', column: 'paymentDate'}
					,{ key: 0, name: '서비스', column: 'service_name'}
					,{ key: 0, name: '구분', column: 'type_name'}
					,{ key: 0, name: '납입구분', column: 'payment_name'}
					,{ key: 0, name: '납입금액', column: 'amount'}
					,{ key: 0, name: '수수료', column: 'fee'}
					,{ key: 0, name: '납입계좌', column: 'account'}
					,{ key: 0, name: '임대인명', column: 'lessor'}
					,{ key: 0, name: '입금자명', column: 'depositor'}
					,{ key: 0, name: '결제상태', column: 'state_name'}
					,{ key: 0, name: '납입상태', column: 'payment_state_name'}
				]
				,content: null
			}
			,excel_data2: {
				name: '월세 납입 대량 이체 파일'
				,header: [
					{ key: 0, name: '은행코드', column: 'code'}
					,{ key: 0, name: '', column: 'wDate', text: ''}
					,{ key: 0, name: '계좌번호', column: 'account'}
					,{ key: 0, name: '금액', column: 'amount'}
					,{ key: 0, name: '상호', column: 'shopName'}
					,{ key: 0, name: '임대인명', column: 'depositor'}
					,{ key: 0, name: '', column: ''}
					,{ key: 0, name: '', column: 'name', text: ''}
					,{ key: 0, name: '', column: 'name2', text: ''}
					,{ key: 0, name: '', column: 'rDate', text: ''}
					,{ key: 0, name: '', column: 'status_name', text: ''}
					,{ key: 0, name: '비고', column: '', text: 'PG대금'}
				]
				,content: null
			}
			,items: []
			,item: {}
			,item_payment: []

			,summary: []
			,is_modal: false
			,is_modal2: false
			,is_modal3: false
			,is_all: false
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.monthly_service.length; i++){
					if(item.service == self.codes.monthly_service[i].code){
						item.service_name = self.codes.monthly_service[i].name
						item.service_color = self.codes.monthly_service[i].color
						break
					}
				}
				for(let i = 0; i < self.codes.monthly_type.length; i++){
					if(item.type === self.codes.monthly_type[i].code){
						item.type_name = self.codes.monthly_type[i].name
						item.service_color = self.codes.monthly_type[i].color
						break
					}
				}
				for(let i = 0; i < self.codes.monthly_payment.length; i++){

					if (item.paymentType == self.codes.monthly_payment[i].code) {
						item.payment_name = self.codes.monthly_payment[i].name
						item.payment_color = self.codes.monthly_payment[i].color
						break
					}
				}
				for(let i = 0; i < self.codes.monthly_payment_state2.length; i++){
					if(item.state === self.codes.monthly_payment_state[i].code){
						item.payment_state_name = self.codes.monthly_payment_state[i].name
						item.payment_state_color = self.codes.monthly_payment_state[i].color
						break
					}
				}
				for(let i = 0; i < self.codes.monthly_payment_state.length; i++){
					if(item.paymentState === self.codes.monthly_payment_state2[i].code){
						item.state_name = self.codes.monthly_payment_state2[i].name
						item.state_color = self.codes.monthly_payment_state2[i].color
						break
					}
				}

				return item
			})
		}
		,select_items: function(){
			let items = []
			this.items.filter(function(item){
				if(item.is_select){
					items.push(item.idx)
				}
			})

			return items
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$layout.onLoading()

				this.$set(this.search, 'startDate', this.search.sDate)
				this.$set(this.search, 'endtDate', this.search.eDate)
                if(this.search.search_type) {
                    this.search.keywordType = this.search.search_type
                } else {
                    delete this.search.keywordType
                }
				const result = await this.$Axios({
					method: 'get'
					,url: 'monthly/payment'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.$set(this.search, 'total_count', result.data.tableList.totalCount)
					this.summary = result.data.statistics
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,toDetail: function(item){
			this.close()
			this.item = item
			this.is_modal = true
		}
		,reset: function(){
			this.search = {
				route: this.$route.name
				,page:1
				,size:10
				,search_type:''
				,search_value:''
				,sDate: this.date.getLastDate(this.date.getToday('-'), 7, '-')
				,eDate: this.date.getToday('-')
				,search_date_type: this.query.search_date_type ? this.query.search_date_type : 'weekly'
				,service: this.query.service ? this.query.service : '0'
				,type: this.query.type ? this.query.type : ''
				,paymentType: this.query.paymentType ? this.query.paymentType : ''
				,payment: this.query.payment ? this.query.payment : ''
				,state: this.query.status ? this.query.status : ''
				,startDate: ''
				,endDate: ''
				,total_count: 0
			}

			this.getData()
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}

		,toExcel: function(){
			this.excel_data.content = this.items
			this.is_excel = true
		}
		,toExcel2: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'get'
					,url: 'monthly/payment/bulk/excel'
					,data: this.search
				})

				if(result.success){
					this.excel_data2.content = result.data.data
					this.is_excel2 = true
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$layout.offLoading()
			}
		}
		,setSearchDateType: function(type){
			this.search.search_date_type = type
		}
		,close: function(){
			this.is_modal = false
			this.is_modal2 = false
			this.is_modal3 = false
		}
		,cancel: async function(){

		}
		,isDeposit: function(){
			this.is_modal2 = true
		}
		,doConfirm: async function(){
			try{
				this.$layout.onLoading()
				const result = await this.$Axios({
					method: 'put'
					,url: 'monthly/payment/confirm'
					,data: {
						service: this.search.service
						,targetIdxList: this.select_items
					}
				})

				if(result.success){
					this.close()
					this.getSearch()
					this.$layout.setNotify( { type: 'success', message: result.message})
				}else{
					this.$layout.setNotify( { type: 'error', message: result.message})
				}
                this.is_all = false
			}catch(e){
				this.$layout.setNotify( { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.close()
				this.$layout.offLoading()
			}
		}

		,toKakao: function(){
			this.close()
			this.is_modal3 = true
		}
		,setNotify: function({ type, message }){
			this.$layout.setNotify( { type: type, message: message})
		}
	}
	,created() {
        this.$layout = this.$root.$children[0].$children[0]
        this.user = this.$layout.user
        this.$layout.onLoad(this.program)
		this.getData()
	}
	,watch: {
		is_all:{
			handler: function(call){
				let self = this
				this.items.filter(function(item){
					self.$set(item, 'is_select', call)
				})
			}
		}
		,'search.search_date_type': {
			immediate: true
			, handler: function(call){
                if (!this.$layout) {
                    this.$layout = this.$root.$children[0].$children[0]
                    this.user = this.$layout.user
                }
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					case 'today':
						this.search.sDate = today

						this.getData()
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')

						this.getData()
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')

						this.getData()
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>

<style>
.bg-icon-calc-quest { background: url('../../assets/images/icon/icon-calc-quest.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-calc { background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-ext { background: url('../../assets/images/icon/icon-round-exc.svg') no-repeat 10px center; padding-left: 70px;}

.img-kakao { left: 8px; width: 15%}
.icon-fail { left: 9px;}
</style>